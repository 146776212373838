import React, { useState, useEffect } from "react";
import Toolbar from "./Toolbar";
import axios from 'axios';

const BoardUser = props => {
  const [data, setData] = useState(props.data);
  //const [authUser, setAuthUser] = useState(props.authUser);

  const dataSetter = async () => {
    let theData = await props.data;
    return theData;
  }

  // const getUploadFiles = async (fileName) => {
  //   const res = await axios.get(`/getFiles/${fileName}`);
  //   if(res){
  //     return res.data;
  //   }
  // }

  useEffect(() => {
    dataSetter().then(r => {
      setData(r);
    });
  }, [props.data]);

  useEffect(() => {
      if(data.length !== 0){
        document.getElementsByClassName("no-apps")[0].style.display = 'none';
        document.getElementsByClassName("spinner-border")[0].style.display = 'none';
      }else{
        document.getElementsByClassName("spinner-border")[0].style.display = 'none';
        document.getElementsByClassName("no-apps")[0].style.display = 'block';
      }
  }, [data]);

  // const removeApp = (name) => {
  //   let d = [...data];
  //   let test = d.filter(app => {
  //     return app.title === name;
  //   });
  //   test[0].active = false;
  //   setData(d);
  // }

  // const addApp = (name) => {
  //   let d = [...data];
  //   let test = d.filter(app => {
  //     return app.title === name;
  //   });
  //   test[0].active = true;
  //   setData(d);
  // }

  const updateData = () => {
    props.updateData();
  }

  return (
    <div className="container-fluid">
      {/* <Toolbar data={data} removeApp={removeApp} addApp={addApp} updateData={updateData} getUploadFiles={getUploadFiles} /> */}
      <Toolbar data={data} updateData={updateData} authUser={props.authUser}/>
    </div>
  );
};

export default BoardUser;