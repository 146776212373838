// change these values accordingly

//localhost
// const SSO_URL = "https://localhost:5001";
// const AIPORTAL_URL = "http://localhost:3000";

//staging
const SSO_URL = "https://auth.aimediagroup.com";
const AIPORTAL_URL = "https://portal.aimediagroup.com";

//production
// const SSO_URL = "";
// const AIPORTAL_URL = "https://ailoginportal.azurewebsites.net";

// prod ai portal app url - https://ailoginportal.azurewebsites.net
// staging SSO url - https://aiauthentication-staging.azurewebsites.net
// dev SSO url - https://ai-login-gateway-dev.azurewebsites.net

export const REACT_APP_AUTH_URL = SSO_URL;
export const REACT_APP_AUTH_LOGOUT_URL = SSO_URL+"/Account/Logoff";
export const REACT_APP_IDENTITY_CLIENT_ID = "aiportal";
export const REACT_APP_REDIRECT_URL = AIPORTAL_URL+"/signin-oidc";
export const REACT_APP_LOGOFF_REDIRECT_URL = AIPORTAL_URL;

export const SSO_API_User_URL = SSO_URL+"/Api/User";
export const SSO_API_App_URL = SSO_URL+"/Api/App";
