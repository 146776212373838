import React, { useEffect } from 'react'
import { signinRedirectCallback } from '../services/userauth.service'

const SigninOidc = (props) => {
    debugger;
    useEffect(() => {
      async function signinAsync() {
        await signinRedirectCallback()
        props.history.push('/home')
      }
      signinAsync()
    }, [])
  
    return (
        <div className="dragzone-div">  
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div> 
    </div>
    )
  }
  
  export default SigninOidc