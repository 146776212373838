import React, { useEffect } from "react";
import { signinRedirect,loadUserFromStorage } from '../services/userauth.service';
import AuthService from "../services/auth.service";

const UserLogin = (props) => {
    useEffect(() => {
        async function loadUserAsync() {
            const user = await loadUserFromStorage()
            if(user){
                let currentUserDetails = await AuthService.getCurrentUserDetails(user);
                props.setUser(currentUserDetails,user);
            }else{
                signinRedirect();
            }
          }
          loadUserAsync();
      }, []);

    return (       
        <div className="dragzone-div">  
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div> 
    </div>
    );
  }
  
  export default UserLogin;