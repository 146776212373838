import React, { useEffect } from 'react'
import { signoutRedirect,loadUserFromStorage } from '../services/userauth.service'

const Logout = () => {
    useEffect(() => {
      async function signoutAsync() {
        const user = await loadUserFromStorage()
        if(user){
            await signoutRedirect(user.id_token)
        }
      }
      signoutAsync()
    }, [])
  
    return (
      <div>
        Redirecting...
      </div>
    )
  }
  
  export default Logout