import React, { useState, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AuthService from "./services/auth.service";
import Login from "./components/Login";
import Register from "./components/Register";
import BoardUser from "./components/BoardUser";
import Profile from "./components/Profile";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import PrivateRoute from "./components/PrivateRoute";
import SigninOidc from "./components/SigninOidc";
import SignoutOidc from "./components/SignoutOidc";
import Logout from "./components/Logout";
import UserLogin from "./components/UserLogin";
import Invalid from "./components/Invalid";
import { Fragment } from "react";

const App = () => {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [authUser, setAuthUser] = useState(undefined);
  const [data, setData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    // const user = AuthService.getCurrentUser();
    // if (user) {
    //   const data = AuthService.getData(user);
    //   setData(data);
    //   setCurrentUser(user);
    // }

    if(currentUser){
      history.push('/home');
    }
  }, []);

  const updateData = () => {
    // const user = AuthService.getCurrentUser();
    // if (user) {
    //   const data = AuthService.getData(user);
    //   setData(data);
    // }
    async function loadUserAsync() {
      //const user = await loadUserFromStorage()
      if(authUser){
          let currentUserDetails = await AuthService.getCurrentUserDetails(authUser);
          setData(currentUserDetails.applications);
      }
    }
    loadUserAsync();
  }

  // const logOut = () => {
  //   AuthService.logout();
  // };

  function updateCurrentUser(currentUserDetails, authUser){
    if(currentUserDetails){
      if(currentUserDetails.userName.split('@')[1] == "aimediagroup.com"){
        setAuthUser(authUser);
        setCurrentUser(currentUserDetails);
        setData(currentUserDetails.applications);
        history.push('/home');
      }else{
        history.push('/Invalid');
      }
    }else{
      signOut();
    }
  }

  function signOut() {
    history.push('/logout');
  }

  const downSymb = <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
  </svg>;

  return (
    <div className="main">
      <nav className="navbar navbar-expand navbar-dark bg-dark px-5">
        <img className="nav" src="/img/logo.png" />
        <div className="navbar-nav mr-auto">Apps Portal Login</div>
        
        {currentUser ? (
          <div className="dropdown">
            <div className="btn btn-secondary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Welcome, {currentUser.firstName} {currentUser.lastName} {downSymb}
            </div>
            <div className="dropdown-menu dropdown-menu-right">
              <a className="dropdown-item" onClick={() => signOut()}>Log Out</a>
            </div>
          </div>
        ) : ( null )}
      </nav>

      <div className="mt-3 px-5">
        <Switch>
          <Route exact path="/signout-oidc" component={SignoutOidc} />
          <Route exact path="/signin-oidc" component={SigninOidc} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/Invalid" component={Invalid} />
          {/* <Route exact path="/" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/recover" component={ForgotPassword} />
          <Route exact path="/reset/:token" component={ResetPassword} /> 
          <PrivateRoute path="/home" render={(props) => <BoardUser {...props} data={data} updateData={updateData} />} />*/}
          <PrivateRoute path="/home" setUser={updateCurrentUser} currentUser={currentUser} render={(props) => <BoardUser {...props} data={data} authUser={authUser} updateData={updateData}/>} />
          <Route exact path="/" render={(props) => <UserLogin {...props} setUser={updateCurrentUser}/>}/>
        </Switch>
      </div>
    </div>
  );
};

export default App;