import React, { useEffect } from 'react'
import { signoutRedirectCallback } from '../services/userauth.service'

const SignoutOidc = (props) => {
    useEffect(() => {
        debugger;
      async function signoutAsync() {
        await signoutRedirectCallback()
      }
      signoutAsync()
    }, [props])
  
    return (
      <div>
        Redirecting...
      </div>
    )
  }
  
  export default SignoutOidc