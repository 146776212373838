import { UserManager,WebStorageStateStore } from 'oidc-client';
import {REACT_APP_AUTH_URL,REACT_APP_IDENTITY_CLIENT_ID,REACT_APP_REDIRECT_URL,REACT_APP_LOGOFF_REDIRECT_URL} from '../common/auth.const';

const config = {
    authority: REACT_APP_AUTH_URL,
    client_id: REACT_APP_IDENTITY_CLIENT_ID,
    redirect_uri: REACT_APP_REDIRECT_URL,
    //response_type: "code", //add grant type: authorization_code
    response_mode: "fragment",
    monitorSession: true,
    loadUserInfo: true,
    response_type: "id_token token",   //add grant type: implicit
    scope: "openid profile IdentityServerApi email",
    post_logout_redirect_uri: REACT_APP_LOGOFF_REDIRECT_URL,
  };

  const metadata = {
    issuer: REACT_APP_AUTH_URL,
    jwks_uri: REACT_APP_AUTH_URL + "/.well-known/openid-configuration/jwks",
    authorization_endpoint: REACT_APP_AUTH_URL + "/connect/authorize",
    token_endpoint: REACT_APP_AUTH_URL + "/connect/token",
    userinfo_endpoint: REACT_APP_AUTH_URL + "/connect/userinfo",
    end_session_endpoint: REACT_APP_AUTH_URL + "/connect/endsession",
    check_session_iframe: REACT_APP_AUTH_URL + "/connect/checksession",
    revocation_endpoint: REACT_APP_AUTH_URL + "/connect/revocation",
    introspection_endpoint: REACT_APP_AUTH_URL + "/connect/introspect"
  }

  //const userManager = new UserManager(config)
  const userManager = new UserManager({
    ...config,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    metadata: {
        ...metadata
    }
});

export async function loadUserFromStorage() {
    let user = null;
    try {
      user = await userManager.getUser()
      if(!user){
        return await signinRedirectCallback();
      }
    } catch (e) {
      console.error(`User not found: ${e}`)
    }

    return user;
  }

  export function signinRedirect() {
    return userManager.signinRedirect()
  }

  export async function signinRedirectCallback() {
    return await userManager.signinRedirectCallback().then(function (u) {
      "";
    }).catch(function (e) {
        console.error(e);
    });
  }

  export function signoutRedirect(prop) {
    localStorage.removeItem("user");  
    userManager.clearStaleState()
    userManager.removeUser()
    return userManager.signoutRedirect().then(function (resp){
      console.log("Success");
    }).catch(function(err){
        console.log(err);
    });
  }
  
  export function signoutRedirectCallback() {
    userManager.clearStaleState()
    userManager.removeUser()
    return userManager.signoutRedirectCallback()
  }

  userManager.events.addUserSignedOut(function(){
    localStorage.removeItem("user");
    userManager.clearStaleState()
    userManager.removeUser()
    userManager.signinRedirect()
  });

  export default userManager