import axios from "axios";
import {SSO_API_User_URL,SSO_API_App_URL} from '../common/auth.const';

const API_URL = "/api/auth/";

async function getCurrentUserDetails(user){
  axios.defaults.headers.common['Authorization'] = user.access_token ? 'Bearer ' + user.access_token : '';
  return await axios.get(SSO_API_User_URL +"/"+ parseInt(user.profile.sub))
  .then((response) => {
    if (response.status == 200) {
      localStorage.setItem("user", JSON.stringify(response.data));
      return response.data;
    }
    else if (response.status == 401) {
      return null;
    }

    return null;
  }).catch( (err) => {
    console.log(err);
})
};

async function updateApplicationPosition(idA, posA, idB, posB, userId, user){
  axios.defaults.headers.common['Authorization'] = user.access_token ? 'Bearer ' + user.access_token : '';
  return await axios.post(SSO_API_App_URL, {
    ApplicationAId: idA, PositionA: posA, ApplicationBId: idB, PositionB: posB, UserId: userId
  })
  .then((response) => {
    if (response.status == 200) {
      return "success";
    }

    return null;
  });
};

const register = (email, firstName, lastName, password) => {
  return axios.post(API_URL + "signup", {
    email,
    firstName,
    lastName,
    password,
  });
};

const login = (email, password) => {
  return axios.post(API_URL + "signin", {
    email,
    password,
  })
  .then((response) => {
    if (response.data.accessToken) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  })
};

const getData = (user) => {
  return axios.post("/api/apps/all", {
    email: user.id,
  })
  .then((response) => {
    localStorage.setItem("appsNum", response.data.length);
    return response.data;
  });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("appsNum");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const removeApp = (title) => {
  let email = JSON.parse(localStorage.getItem("user")).id;
  return axios.post("/api/apps/remove", {
    title,
    email,
  })
  .then((response) => {
    console.log("response in auth service: ", response);
  })
};

const deleteApp = (title) => {
  let email = JSON.parse(localStorage.getItem("user")).id;
  return axios.post("/api/apps/delete", {
    title,
    email,
  })
  .then((response) => {
    console.log("response in auth service: ", response);
  })
};

const addApp = (title) => {
  let email = JSON.parse(localStorage.getItem("user")).id;
  return axios.post("/api/apps/add", {
    title,
    email,
  })
  .then((response) => {
    console.log("response in auth service: ", response);
  })
};

const newApp = (title, subtitle, logo, url, position) => {
  if(subtitle === ''){
    subtitle = null;
  }
  if(!url.startsWith("https://") || !url.startsWith("http://")){
      url = 'https://' + url;;
  }
  logo = "/uploads/" + logo;
  let email = JSON.parse(localStorage.getItem("user")).id;

  return axios.post("/api/apps/new", {
    title,
    subtitle,
    logo,
    url,
    position,
    email
  })
  .then((response) => {
    console.log("response: ", response);
  })
};

const updatePosition = (idA, posA, idB, posB, userId) => {
  return axios.post("/api/apps/updatePosition", {
    idA, posA, idB, posB, userId
  }).then(response => {
    return response;
  })
};

export default {
  register,
  login,
  getData,
  logout,
  getCurrentUser,
  removeApp,
  deleteApp,
  addApp,
  newApp,
  updatePosition,
  getCurrentUserDetails,
  updateApplicationPosition
};