import React, {useState, useEffect, Fragment} from 'react';
import Dashboard from './Dashboard';
import { FaPencilAlt, FaCheck } from 'react-icons/fa';
import FileUpload from './FileUpload';
import { loadUserFromStorage } from '../services/userauth.service';
import AuthService from "../services/auth.service";

function Toolbar(props) {
    const [search, setSearch] = useState(null);
    const [apps, setApps] = useState([]);
    //const [editMode, setEditMode] = useState(false);
    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        if(search === null) {
            let activeApps = props.data.filter(app => {
                return app.active === true;
            });
            setApps(activeApps);
        } else {
            let activeApps = props.data.filter(app => {
                if(app.title.toLowerCase().includes(search.toLowerCase()) && app.active === true){
                    return app;
                }
            });
            setApps(activeApps);
        }
    }, [props.data]);

    // useEffect(() => {
    //     console.log("Here 4", apps);
    // }, [apps]);

    useEffect(() => {
        let items = props.data.filter(app => {
            if(search == null){
                return app.active === true;
            }
            else if(app.title.toLowerCase().includes(search.toLowerCase()) && app.active === true){
                return app;
            }
        });
        setApps(items);
    }, [search]);

    useEffect(() => {
        if(updated){
            props.updateData();
            setUpdated(false);
        }
    }, [updated]);

    const searchSpace = (event) => {
        let keyword = event.target.value;
        setSearch(keyword);
    };

    // const editClick = () => {
    //     let closeBtnEl = document.getElementsByClassName("close-btn");
    //     let editBtn = document.getElementById("edit-btn");
        
    //     // when not in edit mode
    //     if(editMode === false){
    //         editBtn.classList.add("edit-active");
    //         for(let i = 0; i < closeBtnEl.length; i++){
    //             closeBtnEl[i].classList.remove("hidden");
    //         }
    //         setEditMode(true);
    //     // when in edit mode
    //     } else {
    //         editBtn.classList.remove("edit-active");
    //         for(let i = 0; i < closeBtnEl.length; i++){
    //             closeBtnEl[i].classList.add("hidden");
    //         }
    //         setEditMode(false);
    //     }
    // }

    // const removeApp = (title) => {
    //     props.removeApp(title);
    //     AuthService.removeApp(title);
    // }

    // const addApp = (title) => {
    //     props.addApp(title);
    //     AuthService.addApp(title);
    // }

    const updatePosition = async (appA, appB) => {
        let idA = appA.id;
        let posA = appA.position;
        let idB = appB.id;
        let posB = appB.position;
        let userId = JSON.parse(localStorage.getItem('user')).id;
        let user = props.authUser;
        
        //const res = await AuthService.updatePosition(idA, posA, idB, posB, userId);
        if(user){
            const res = await AuthService.updateApplicationPosition(idA, posA, idB, posB, userId, user);
            if(res === 'success'){
                setUpdated(true);
            }
        }
    }

    // const appsList = () => {
    //     return props.data.map(app => {
    //         if(app.active === true){
    //             return <button className="dropdown-item" key={app.title} onClick={() => removeApp(app.title)}><FaCheck color="green" /> {app.title}</button>;
    //         } else {
    //             return <button className="dropdown-item" key={app.title} onClick={() => addApp(app.title)}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{app.title}</button>;
    //         }
    //     })
    // }

    return(
        <Fragment>
            <div className="toolbar">
                <input type="text" placeholder="Search existing apps" onChange={(e)=>searchSpace(e)} />
                {/* <h4>RESET</h4> */}
                {/* <div className="toolbar-btns">
                    <FaPencilAlt size="2.25em" id="edit-btn" onClick={() => editClick()} />
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle drop-btn" type="button" id="newAppMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span>New App</span>
                            <span>+</span>
                        </button>
                        <div className="dropdown-menu" id="newAppMenu" aria-labelledby="newAppMenuButton">
                            <h6 className="dropdown-header">New App</h6>
                            {appsList()}
                        </div>
                    </div>
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle drop-btn" type="button" id="personalLinkMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span>Add Personal Link</span>
                            <span>+</span>
                        </button>
                        <div className="dropdown-menu" id="personalLinkMenu" aria-labelledby="personalLinkMenuButton">
                            <h6 className="dropdown-header">Add Personal Link</h6>
                            <div className="dropdown-divider"></div>
                            <FileUpload />
                        </div>
                    </div>
                </div> */}
            </div>   
            <div className="dragzone-div">  
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className="no-apps">
                    No Applications
                </div>  
                {/* <Dashboard apps={apps} removeApp={removeApp} editMode={editMode} updatePosition={updatePosition} editMode={editMode} getUploadFiles={props.getUploadFiles} /> */}
                <Dashboard apps={apps} updatePosition={updatePosition}/>
            </div>
        </Fragment>
    );
}

export default Toolbar;