import React, { useEffect } from 'react'
const Invalid = (props) => {
  
    return (
        <div className="dragzone-div">  
            <div>Invalid user. Please contact your administrator for assistance..</div>
        </div>
    )
  }
  
  export default Invalid