import React, { useState, useEffect, useRef } from 'react';

function Dashboard(props) {
    const [apps, setApps] = useState(props.apps);
    const [dragging, setDragging] = useState(false);
    const [appA, setAppA] = useState(null);
    const [appB, setAppB] = useState(null);
    const [updated, setUpdated] = useState(false);

    // const [uploadFile, setUploadFile] = useState(null);

    // const[logos, setLogos] = useState([]);

    const dragItem = useRef();
    const dragItemNode = useRef();

    useEffect(() => {
        setApps(props.apps);
    }, [setApps, props.apps]);

    useEffect(() => {
        if(appA && appB && appA.id !== appB.id && updated){
            props.updatePosition(appA, appB);
            setUpdated(false);
        }
    }, [updated]);

    // useEffect(() => {
    //     let appCards = document.getElementsByClassName('app-card');
    //     if(appCards.length !== 0){
    //         let appCardsArray = Array.from(appCards);
    //         if(props.editMode){
    //             appCardsArray.map(card => {
    //                card.setAttribute('draggable', true);
    //            });
    //         } else {
    //             appCardsArray.map(card => {
    //                 card.setAttribute('draggable', false);
    //             });
    //         }
    //     }
    // }, [props.editMode]);

    // const testing = async (logo) => {
    //     let newUrl = logo.slice(logo.lastIndexOf('/') + 1);
    //     // console.log("newurl", newUrl);
    //     // let currentArray = [...logos];
    //     // console.log("currentarray", currentArray);

    //     await props.getUploadFiles(newUrl).then(r => {
    //         // console.log("rrrr", newUrl, r);
    //         // let newData = {[newUrl] : `data:image/png;base64, ${r}`};
    //         let newData = {logoUrl: logo, logoData: `data:image/png;base64, ${r}`};
    //         setLogos(prevState => [...prevState, newData]);
    //     });
    // }

    // useEffect(() => {
    //     props.apps.map(app => {
    //         if(app.logo.includes("/uploads/")){
    //             testing(app.logo);
    //         }
    //         // console.log("WHAT IS IT", logos);
    //     });
    //     // console.log("PLEASE GAWD: ", logos);
    // }, [props.apps]);

    const closeClick = (e) => {
        let nextEl = e.target.parentElement.getElementsByTagName('small')[0].innerText;
        props.removeApp(nextEl);
    }

    // in progress
    // const deleteClick = (e) => {
    //     let nextEl = e.target.parentElement.getElementsByTagName('small')[0].innerText;
    //     props.deleteApp(nextEl);
    // }

    const navigate = (e, link) => {
        e.preventDefault();
        if(props.editMode){
            return false;
        } else{
            window.open(link, '_blank');
        }
    }

    const handleDragStart = (e, params) => {
        dragItemNode.current = e.target;
        dragItemNode.current.addEventListener('dragend', handleDragEnd);
        dragItem.current = params.index;
        setAppA(params.app);
        setTimeout(() => {
            setDragging(true); 
        },0)  
    }

    const handleDragEnter = (e, targetItem) => {
        if ((dragItemNode.current !== e.target) && (dragItemNode.current !== e.target.parentElement) && (dragItemNode.current !== e.target.parentElement.parentElement)) {
            setApps(oldList => {
                let newList = JSON.parse(JSON.stringify(oldList));
                // meaning, remove 0 elements before index targetItem and insert the 3rd argument before index targetItem
                newList.splice(targetItem.index, 0, newList.splice(dragItem.current, 1)[0]);
                dragItem.current = targetItem.index;
                if(appA.id !== targetItem.app.id){
                    setAppB(targetItem.app);
                }
                return newList;
            })
        }
    }

    const handleDragEnd = () => {
        setDragging(false);
        setUpdated(true);
        dragItem.current = null;
        dragItemNode.current.removeEventListener('dragend', handleDragEnd);
        dragItemNode.current = null;
    }

    const getStyles = (item) => {
        if (dragItem.current === item) {
            return "card mb-4 app-card current"
        }
        return "card mb-4 app-card"
    }

    // const uploadedLogo = async (url) => {
    //     url = url.slice(url.lastIndexOf('/') + 1);
    //     // await props.getUploadFiles(url).then(r => {
    //     //     setUploadFile(`data:image/png;base64, ${r}`);
    //     // });
    //     return await props.getUploadFiles(url).then(r => {
    //         return `data:image/png;base64, ${r}`;
    //     });
    // }

    // const getLogo = (url) => {
    //     // console.log("official?", logos);
    //     if(url.includes("/uploads/")){
    //         // uploadedLogo(url);
    //         // console.log(uploadFile);
    //         // return uploadFile;

    //         // return uploadedLogo(url).then( r => {
    //         //     return r;
    //         // })

    //         let theLogoObj = logos.find(logoObj => {
    //             return Object.keys(logoObj).find(key => logoObj[key] === url);
    //         });
    //         if(theLogoObj){
    //             // console.log("THE LOGO OBJECT: ", theLogoObj.logoData);
    //             return theLogoObj.logoData;
    //         }
    //         // return theLogoObj.logoData;
    //     } else {
    //         return url;
    //     }
    // }

    // const getLogo2 = (url) => {

    // }

    // const uploadedAFile = () => {

    // }

    return(
        <div className="wrapper card-deck">
        {
            apps && apps.map((app, index) => {
                return (
                    <div 
                        // draggable
                        key={index}
                        onDragStart={ (e) => handleDragStart(e, {index, app}) } // changed from app.id to index
                        onDragEnter={ dragging ? (e) => handleDragEnter(e, {index, app}) : null } // changed from app.id to index
                        className={ dragging ? getStyles(index) : (props.editMode ? "card mb-4 app-card animate__animated animate__headShake animate__infinite" : "card mb-4 app-card") }
                        onClick={(e) => navigate(e, `${app.url}`)}
                    >
                        <div className="close-btn hidden" onClick={ (e) => closeClick(e) }>x</div>
                        {/* <div className="delete-btn hidden" onClick={ (e) => closeClick(e) }>Delete Forever</div> */}
                    {
                        app.subtitle !== null ?
                        <div className="card-content">
                            <img className="card-img-top img-fluid" src={app.logo} alt="Card image cap" />
                            <p className="card-text">{app.subtitle}</p>
                        </div>
                        :
                        <img className="card-img-top img-fluid" src={app.logo} alt="Card image cap" />
                    }
                        <div className="card-footer"><small className="text-muted">{app.title}</small></div>
                    </div>
                )
            })
        }
        </div>
    );
}

export default Dashboard;